import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "colorPicker",
    "colorText",
    "template",
    "batchQuestionRowPlaceholder",
  ];

  static values = {
    modelName: String,
  };

  connect() {}

  updatePickerValue() {
    this.colorPickerTarget.value = this.colorTextTarget.value;
  }

  updateTextValue() {
    this.colorTextTarget.value = this.colorPickerTarget.value;
  }

  addBatchApplicationQuestion() {
    let template = this.templateTarget;
    let clone = document.importNode(template.content, true);
    let model_name = this.modelNameValue;

    var unused_batch_questions = document.getElementById(
      model_name + "_unused_batch_questions"
    );
    var question_to_add_name = unused_batch_questions.value;
    var question_to_add_type =
      unused_batch_questions.selectedOptions[0].dataset.fieldtype;
    var question_to_add_template =
      unused_batch_questions.selectedOptions[0].dataset.template;

    let next_index = template.dataset.next_index;

    let name_label = clone.querySelector(
      'label[for="' + model_name + "_batch_questions_" + next_index + '_name"]'
    );
    name_label.innerText = question_to_add_name;
    let name_input = clone.querySelector(
      "#" + model_name + "_batch_questions_" + next_index + "_name"
    );
    name_input.setAttribute("value", question_to_add_name);

    let type_label = clone.querySelector(
      'label[for="' + model_name + "_batch_questions_" + next_index + '_type"]'
    );
    type_label.innerText = question_to_add_type;
    let type_input = clone.querySelector(
      "#" + model_name + "_batch_questions_" + next_index + "_type"
    );
    type_input.setAttribute("value", question_to_add_type);

    let template_label = clone.querySelector(
      'label[for="' +
        model_name +
        "_batch_questions_" +
        next_index +
        '_template"]'
    );

    let template_value =
      question_to_add_template == undefined ? "" : question_to_add_template;

    template_label.innerText = template_value;
    let template_input = clone.querySelector(
      "#" + model_name + "_batch_questions_" + next_index + "_template"
    );
    template_input.setAttribute("value", template_value);

    // Remove batch question as an option from the dropdown
    unused_batch_questions.remove(unused_batch_questions.selectedIndex);
    if (!unused_batch_questions.options.length) {
      var add_batch_question_section = document.getElementById(
        "add_batch_question_section"
      );
      add_batch_question_section.style.display = "none";
    }

    // Replace placeholder row with template clone
    this.batchQuestionRowPlaceholderTarget.replaceWith(clone);

    // Update template index for next row
    let new_index = (parseInt(next_index) + 1).toString();

    let template_element = document.getElementById(
      "next_batch_question_template"
    );
    template_element.setAttribute("data-next_index", new_index);
    let row_element = template_element.content.firstElementChild;

    let input_elements = row_element.getElementsByTagName("input");
    for (let i = 0; i < input_elements.length; i++) {
      let new_name = input_elements[i].name.replace(next_index, new_index);
      input_elements[i].setAttribute("name", new_name);
      let new_id = input_elements[i].id.replace(next_index, new_index);
      input_elements[i].setAttribute("id", new_id);
    }

    let label_elements = row_element.getElementsByTagName("label");
    for (let i = 0; i < label_elements.length; i++) {
      let new_for_attribute = label_elements[i]
        .getAttribute("for")
        .replace(next_index, new_index);
      label_elements[i].setAttribute("for", new_for_attribute);
    }
  }
}
