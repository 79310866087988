import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "authenticatesInTypeInput",
    "authenticatesInUniversityInput",
    "authenticatesInHighSchoolInput",
  ];

  connect() {}

  selectUniversity() {
    this.authenticatesInTypeInputTarget.value = "University";
    this.authenticatesInHighSchoolInputTarget.value = null;
  }

  selectHighSchool() {
    this.authenticatesInTypeInputTarget.value = "HighSchool";
    this.authenticatesInUniversityInputTarget.value = null;
  }
}
