import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "loadingIndicator",
    "homeCollegeContainer",
    "universitySelectContainer",
    "input",
    "searchValueContainer",
    "searchWarningContainer",
    "searchValue",
  ];

  connect() {
    this._hideLoadingIndicator();
  }

  clearFilter() {
    this.inputTarget.selectize.clear(true);
  }

  updateSearchBar(event) {
    this._showLoadingIndicator();
    if (this.homeCollegeContainerTarget.contains(event.target)) {
      if (event.target.name === "search_all_universities") {
        this.universitySelectContainerTarget.style.display =
          event.target.value === "true" ? "none" : "block";
        this.clearFilter();

        if (event.target.value === "true") {
          this._showSearchValueContainer();
        } else {
          this._hideSearchValueContainer();
        }
      }

      if (event.target.name === "university_id") {
        if (event.target.value === "") {
          this._hideSearchValueContainer();
        } else {
          this._showSearchValueContainer();
        }
      }
    }
    this._hideLoadingIndicator();
  }

  submitForm(event) {
    this._showLoadingIndicator();
  }

  _showLoadingIndicator() {
    if (this.hasLoadingIndicatorTarget)
      this.loadingIndicatorTarget.style["display"] = null;
  }

  _hideLoadingIndicator() {
    if (this.hasLoadingIndicatorTarget)
      this.loadingIndicatorTarget.style["display"] = "none";
  }

  _showSearchValueContainer() {
    if (this.hasSearchValueContainerTarget)
      this.searchValueContainerTarget.style["display"] = null;
    if (this.hasSearchWarningContainerTarget)
      this.searchWarningContainerTarget.style["display"] = "none";
  }

  _hideSearchValueContainer() {
    if (this.hasSearchValueContainerTarget)
      this.searchValueContainerTarget.style["display"] = "none";
    if (this.hasSearchWarningContainerTarget)
      this.searchWarningContainerTarget.style["display"] = null;
    if (this.hasSearchValueTarget) this.searchValueTarget.value = "";
  }
}
