/* globals $ */

import { Controller } from "stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["listButton", "optionsList", "checkbox", "container"];
  static values = {
    placeholder: String,
  };

  connect() {
    useClickOutside(this);
    this.optionsChanged();
  }

  toggleList() {
    if (this.listButtonTarget.getAttribute("aria-expanded") == "true") {
      this.hideList();
    } else {
      this.showList();
    }
  }

  hideList() {
    this.listButtonTarget.setAttribute("aria-expanded", false);
    this.optionsListTarget.setAttribute("aria-hidden", true);
    this.listButtonTarget.parentElement.classList.add("closed");
    this.listButtonTarget.parentElement.classList.remove("opened");
  }

  showList() {
    const self = this;
    const focusableElements = ["button", "input"];
    const container = this.containerTarget;

    this.listButtonTarget.setAttribute("aria-expanded", true);
    this.optionsListTarget.setAttribute("aria-hidden", false);
    this.listButtonTarget.parentElement.classList.add("opened");
    this.listButtonTarget.parentElement.classList.remove("closed");

    this.containerTarget.addEventListener("keydown", function (e) {
      if (e.key === "Escape") {
        self.hideList();
        self.listButtonTarget.focus();
        return;
      }

      if (!e.key === "Tab") {
        return;
      }

      var firstFocusableElement =
        container.querySelectorAll(focusableElements)[0];
      var focusableContent = container.querySelectorAll(focusableElements);
      var lastFocusableElement = focusableContent[focusableContent.length - 1];

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          self.hideList();
        }
      } else {
        if (firstFocusableElement) {
          if (document.activeElement === lastFocusableElement) {
            self.hideList();
          }
        }
      }
    });
  }

  clickOutside() {
    this.hideList();
  }

  optionsChanged() {
    const selectedOptions = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    );

    if (selectedOptions.length > 1) {
      this.listButtonTarget.querySelector(
        "span"
      ).textContent = `${selectedOptions.length} options selected`;
    } else if (selectedOptions.length == 1) {
      this.listButtonTarget.querySelector("span").textContent =
        selectedOptions[0].parentElement.querySelector("span").textContent;
    } else {
      this.listButtonTarget.querySelector(
        "span"
      ).textContent = `Select ${this.placeholderValue}`;
    }
  }
}
