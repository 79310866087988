/* globals $ */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "errorsMessages",
    "errorsMessagesContainer",
    "residencyField",
    "form",
    "unofficialTranscriptFields",
    "unofficialTranscriptsErrorContainer",
    "stateError",
    "additionalAnswersCheckbox",
    "noneAdditionalAnswersCheckbox",
    "submitButton",
  ];

  submitForm(e) {
    e.preventDefault();
    if (this.hasUnofficialTranscriptsErrorContainerTarget) {
      this.unofficialTranscriptsErrorContainerTarget.classList.add("hidden");
    }
    if (!this.valid_state()) {
      if (!this.hasStateErrorTarget) {
        document
          .querySelector("#error-list")
          .insertAdjacentHTML(
            "beforeend",
            '<li data-batch-application-form-target="stateError"> ' +
              this.residencyFieldTarget.dataset.stateErrorMessage +
              " </li>"
          );
      }
      this.errorsMessagesTarget.classList.remove("hidden");
      $("html, body").animate(
        {
          scrollTop: $(this.errorsMessagesTarget).first().offset().top - 20,
        },
        500
      );
      setTimeout(
        () => this.submitButtonTarget.removeAttribute("disabled"),
        2000
      );
      return false;
    }
    if (!this.validUnofficialTranscripts()) {
      this.unofficialTranscriptsErrorContainerTarget.classList.remove("hidden");
      setTimeout(
        () => this.submitButtonTarget.removeAttribute("disabled"),
        2000
      );
      return false;
    }
    this.formTarget.submit();
  }

  validUnofficialTranscripts() {
    if (this.unofficialTranscriptFieldsTargets.length == 0) {
      return true;
    }

    return this.unofficialTranscriptFieldsTargets.some((field) => {
      return !!field.value;
    });
  }

  valid_state() {
    if (!this.hasResidencyFieldTarget) {
      return true;
    }
    var state_string = this.residencyFieldTarget.dataset.stateRestriction;
    if (!state_string || state_string.length === 0) {
      return true;
    }
    return $(this.residencyFieldTarget).val() == state_string;
  }

  checkAdditionAnswers() {
    const checkedAnswers = this.additionalAnswersCheckboxTargets.filter(
      (checkbox) => checkbox.checked
    );

    if (checkedAnswers.length > 0) {
      this.additionalAnswersCheckboxTargets.forEach((checkbox) => {
        checkbox.setCustomValidity("");
        checkbox.removeAttribute("required");
      });
      this.noneAdditionalAnswersCheckboxTarget.checked = false;
    } else {
      this.additionalAnswersCheckboxTargets.forEach((checkbox) => {
        checkbox.setAttribute("required", "required");
      });
    }
  }

  noneAdditionalAwswersChecked() {
    if (this.noneAdditionalAnswersCheckboxTarget.checked) {
      this.additionalAnswersCheckboxTargets.forEach((checkbox) => {
        checkbox.checked = false;
        checkbox.removeAttribute("required");
        checkbox.setCustomValidity("");
      });
    } else {
      this.additionalAnswersCheckboxTargets.forEach((checkbox) => {
        checkbox.setAttribute("required", "required");
      });
    }
  }
}
